@import url("https://fonts.googleapis.com/css2?family=Anuphan:wght@200;400;600;700&display=swap");

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  width: 100%;
}

h1 {
    font-family: "Anuphan", sans-serif;
  }
  
h2 {
  font-family: "Anuphan", sans-serif;
  font-weight: 700;
  margin-top: 5px;
  font-size: 20px;
}

h3 {
  font-family: "Anuphan", sans-serif;
  font-weight: 700;
  margin-top: 5px;
  font-size: 18px;
}
